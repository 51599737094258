import Vue from 'vue';
import '@/assets/scss/vue-tables.scss';
import {postBuffer} from '@/services/api';
import {decrypt} from '@/helpers/encrypt';
import {mapGetters, mapMutations, mapState} from 'vuex';
import CreditsView from '@/components/common/CreditsView'
import CreditOverviewTable from '@/components/CreditOverviewTable'
import CurrencyPriceView from '@/components/common/CurrencyPriceView'


export default Vue.extend({
  name: 'credit-history',
  components: {
    CurrencyPriceView,
    CreditsView,
    CreditOverviewTable
  },
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits']),
  },
  data: () => ({
    tableData: [],
    userResume: {
      bought: 0,
      used: 0
    },
    userId: null,
  }),
  mounted() {
    let id = localStorage.getItem('admin-id');
    this.userId = decrypt(id);
  },

  methods: {
    ...mapMutations(['loaderManager']),
    async downloadPdf() {
      this.loaderManager(true);
      postBuffer(`credit-movement/export-overview-pdf`,
        {
          allBalance: false,
          id: this.userId
        },
        true)
        .then(async ({data}) => {
          const fileName = `credit-overview- ${new Date().getTime()}.pdf`;
          const file2 = new File([data], fileName, {type: 'application/pdf'});
          saveAs(file2);
          this.loaderManager(false);
          location.reload()
        })
        .catch(e => {
          this.loaderManager(false);
        });
    },
  }
});

