var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.users.length)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"lg":"4"}},[(_vm.$userCan('administrator'))?_c('h6',{staticClass:"ml-2"},[_vm._v(" Total Paid: € "+_vm._s(_vm._f("number")(_vm.totalBought)))]):_vm._e()]),_c('b-col',{attrs:{"lg":"4"}},[_c('b-select',{model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}},[_c('b-select-option',{attrs:{"value":null}},[_vm._v("Select order status")]),_vm._l((_vm.arrayOrderStatus),function(item,index){return _c('b-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-select',{model:{value:(_vm.filter.userId),callback:function ($$v) {_vm.$set(_vm.filter, "userId", $$v)},expression:"filter.userId"}},[_c('b-select-option',{attrs:{"value":null}},[_vm._v("Select Dealer/Subdealer")]),_vm._l((_vm.users),function(user){return _c('b-select-option',{attrs:{"value":user.id}},[_vm._v(_vm._s(user.fullName))])})],2)],1)],1):_vm._e(),_c('div',{staticClass:"pt-2 dataTables_wrapper VueTables no-shadow table-responsive"},[_c('b-table',{staticClass:"dataTable",attrs:{"small":"","hover":"","bordered":"","items":_vm.arrayData,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [(item.ownerId !== null)?_c('span'):_vm._e(),_vm._v(_vm._s(item.invoiceNumber)+" ")]}},{key:"head(credits)",fn:function(){return [_vm._v(" Credits Ordered "),(!_vm.showInLocal)?_c('img',{staticClass:"symbol",attrs:{"src":_vm.$store.getters['geo/currencyCreditSymbol']}}):_vm._e()]},proxy:true},{key:"cell(credits)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.credit,"currency-details":item.currencyDetails,"no-show-logo":!_vm.showInLocal,"symbol-to-end":""}})]}},{key:"head(totalReceived)",fn:function(){return [_vm._v(" Credits Transferred "),(!_vm.showInLocal)?_c('img',{staticClass:"symbol",attrs:{"src":_vm.$store.getters['geo/currencyCreditSymbol']}}):_vm._e()]},proxy:true},{key:"cell(totalReceived)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.totalReceived,"currency-details":item.currencyDetails,"no-show-logo":!_vm.showInLocal,"symbol-to-end":""}})]}},{key:"cell(discount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount)+" % ")]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":(item.price),"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(totalPaid)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":item.totalPaid,"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(totalPending)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":(item.price) - item.totalPaid,"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDateShort")(item.createdAt))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("background:" + (_vm.getColor(item.status)) + "; color: white; font-weight: bold; padding: 0.2vw;"))},[_vm._v(" "+_vm._s(_vm._f("creditStatus")(item.status)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"btn-primary btn-xs",attrs:{"content":'View credit order detail'},on:{"click":function($event){return _vm.showDetailOrder(item.id)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-pagination',{staticClass:"justify-content-end no-shadow p-2",attrs:{"value":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.rows},on:{"change":_vm.paginate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }