<template>
  <b-card>
    <div class="dataTables_wrapper VueTables no-shadow table-responsive">
      <b-table :items="items" :fields="fields" class="dataTable" small bordered hover>
        <template #head(credit)>
          Credits Ordered
          <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>

        <template #cell(credit)="{item}">
          <credits-view :amount="item.credit" :currency-details="item.currencyDetails" :no-show-logo="!showInLocal"
                        symbol-to-end/>
        </template>

        <template #head(credits_gave)>
          Credits Transferred
          <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(credits_gave)="{item}">
          <credits-view :amount="item.credits_gave" :currency-details="item.currencyDetails"
                        :no-show-logo="!showInLocal" symbol-to-end/>
        </template>

        <template #cell(price)="{item}">
          <currency-price-view :amount="item.price" :currency-details="getCurrencyDetails(item)" no-switch/>
        </template>

        <template #cell(paid)="{item}">
          <currency-price-view :amount="item.paid" :currency-details="getCurrencyDetails(item)" no-switch/>
        </template>

        <template #cell(pending)="{item}">
          <currency-price-view :amount="(item.price) - item.paid" :currency-details="getCurrencyDetails(item)"
                               no-switch/>
        </template>

        <template #cell(Actions)="{item}" class="p-0">
          <b-button variant="primary" @click="view(item)" class="btn-sm">
            <i class="material-icons">visibility</i>
          </b-button>
        </template>
      </b-table>
    </div>

    <b-modal id="modal-list-orders" title="Credit order list" size="lg" hide-footer>
      <h6>User: {{ userName }} </h6>
      <div class="table-responsive">
        <table-order-pendings v-if="arrayPendingOrders.length" :array-pending-orders="arrayPendingOrders"
                              @loadData="loadData"/>
      </div>
    </b-modal>
  </b-card>

</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import CreditsView from "@/components/common/CreditsView";
import TableOrderPendings from "@/views/dashboard/credit-history/TableOrderPendings";
import {mapGetters, mapMutations, mapState} from "vuex";
import {get} from "@/services/api";

const fieldBilling = () => ([{key: 'name', label: 'User', sortable: true},
  {key: 'credit', label: 'Credits Ordered', sortable: true},
  {key: 'credits_gave', label: 'Credits Transferred', sortable: true},
  {key: 'price', label: 'Total Price', sortable: true},
  {key: 'paid', label: 'Total Paid', sortable: true},
  {key: 'pending', label: 'Total Pending', sortable: true},
  'Actions'])

export default {
  name: "TableBillingStatement",
  components: {CurrencyPriceView, CreditsView, TableOrderPendings},
  data: () => ({
    fields: fieldBilling(),
    items: [],
    arrayBillings: [],
    arrayPendingOrders: [],
    userName: null,
  }),
  mounted() {
    this.loadData()
  },

  computed: {
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser']),
    ...mapState('geo', ['showInLocal'])
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async loadData() {
      const {data} = await get("credit-order/billing-statement", null, true)
      this.arrayBillings = data
      const arrayIds = this.getIds(data)
      this.items = this.processData(arrayIds, data)
    },
    getIds(data) {
      return [...new Set(data.map(item => item.user_id))];
    },
    processData(arrayIds, originalData) {
      const newData = []
      for (const el of arrayIds) {
        const data = originalData.filter(x => x.user_id === el)
        const price = this.sum(data, "price") + this.sum(data, "extraFee")
        const extraFee = this.sum(data, "extraFee")
        const paid = this.sum(data, "paid")
        const credits_gave = this.sum(data, "credits_gave")
        const credit = this.sum(data, "credit")
        const pending = price - paid
        if (pending > 0) {
          newData.push({price, paid, pending, credit, credits_gave, user_id: el, name: data[0].name})
        }
      }
      return newData
    },
    sum(data, field) {
      return data.reduce((accumulator, curValue) => {
        return Number(accumulator) + Number(curValue[field])
      }, 0)
    },
    async view(item) {
      this.arrayPendingOrders = []
      this.userName = null
      this.userName = item.name
      const ordersIds = this.arrayBillings.filter(x => x.user_id === item.user_id).map(el => el.id)
      let {data} = await get(`credit-order/orders/${ordersIds.join(',')}`, null, true);
      this.arrayPendingOrders = data;
      this.$bvModal.show("modal-list-orders")
      ordersIds.forEach(el => {
        if (data.data) {
          data.data.forEach(item => {
            if (el.id === item.id) {
              this.arrayPendingOrders.push(item)
            }
          })
        }
      })
    },
    getCurrencyDetails(item) {
      if (!item.ownerId) {
        return this.$store.state.geo.tvsCurrency;
      } else {
        return item.currencyDetails;
      }
    },
  }
}
</script>

<style scoped>
</style>
