<template>
  <b-container class="main-content-container px-4 pb-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="4">
        <span class="text-uppercase page-subtitle">{{ $t('credit_history.title_sent') }}</span>
        <h3 class="page-title">{{ $t('credit_history.subtitle_sent') }}</h3>
      </b-col>
    </b-row>
    <b-tabs  v-if="$userCan('administrator')" fill>
      <b-tab title="Payments" active>
        <table-credit-history-received/>
      </b-tab>
      <b-tab title="Billing statement">
        <table-billing-statement/>
      </b-tab>
    </b-tabs>
      <table-credit-history-received v-else/>
  </b-container>
</template>

<script>
import TableCreditHistoryReceived from "@/views/dashboard/credit-history/TableCreditHistoryReceived";
import TableBillingStatement from "@/views/dashboard/credit-history/TableBillingStatement";
export default {
  name: 'CreditHistoryReceived',
  components:{TableCreditHistoryReceived, TableBillingStatement}
};
</script>


