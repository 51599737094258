<template>
  <b-card>
    <b-row class="mt-3" v-if="users.length">
      <b-col lg="4">
        <h6 class="ml-2" v-if="$userCan('administrator')"> Total Paid: €
          {{ totalBought|number }}</h6>
      </b-col>
      <b-col lg="4">
        <b-select v-model="filter.status">
          <b-select-option :value="null">Select order status</b-select-option>
          <b-select-option :value="item.value" v-for="(item, index) in arrayOrderStatus" :key="index">
            {{ item.text }}
          </b-select-option>
        </b-select>
      </b-col>
      <b-col lg="4">
        <b-select v-model="filter.userId">
          <b-select-option :value="null">Select Dealer/Subdealer</b-select-option>
          <b-select-option v-for="user of users" :value="user.id">{{ user.fullName }}</b-select-option>
        </b-select>
      </b-col>
    </b-row>
    <div class="pt-2 dataTables_wrapper VueTables no-shadow table-responsive">
      <b-table class="dataTable" small hover bordered :items="arrayData" :fields="fields">
        <template #cell(id)="{item}">
          <span v-if="item.ownerId !== null"></span>{{ item.invoiceNumber }}
        </template>
        <template #head(credits)>
          Credits Ordered <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(credits)="{item}">
          <credits-view :amount="item.credit" :currency-details="item.currencyDetails" :no-show-logo="!showInLocal"
                        symbol-to-end/>
        </template>

        <template #head(totalReceived)>
          Credits Transferred
          <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
        </template>
        <template #cell(totalReceived)="{item}">
          <credits-view :amount="item.totalReceived" :currency-details="item.currencyDetails"
                        :no-show-logo="!showInLocal" symbol-to-end/>
        </template>

        <template #cell(discount)="{item}">
          {{ item.discount }} %
        </template>
        <template #cell(price)="{item}">
          <currency-price-view :amount="(item.price)"
                               :currency-details="getCurrencyDetails(item)" no-switch/>
        </template>
        <template #cell(totalPaid)="{item}">
          <currency-price-view :amount="item.totalPaid" :currency-details="getCurrencyDetails(item)" no-switch/>
        </template>
        <template #cell(totalPending)="{item}">
          <currency-price-view :amount="(item.price) - item.totalPaid"
                               :currency-details="getCurrencyDetails(item)" no-switch/>
        </template>
        <template #cell(createdAt)="{item}">
          {{ item.createdAt|localDateShort }}
        </template>
        <template #cell(status)="{item}">
          <span :style="`background:${getColor(item.status)}; color: white; font-weight: bold; padding: 0.2vw;`"> {{
              item.status|creditStatus
            }}</span>
        </template>
        <template #cell(actions)="{item}">
          <b-button
            v-tippy="{ placement : 'top',  arrow: true }" :content="'View credit order detail'"
            class="btn-primary btn-xs"
            @click="showDetailOrder(item.id)">
            <i class="material-icons">visibility</i>
          </b-button>
        </template>
      </b-table>
    </div>
    <div class="d-flex justify-content-end">
      <b-pagination
        class="justify-content-end no-shadow p-2"
        :value="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.rows"
        @change="paginate"
      />
    </div>
  </b-card>
</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import CreditsView from "@/components/common/CreditsView";
import {Pagination} from "@/utils/pagination";
import {ORDER_CREDIT_STATUS} from "@/constants";
import {decrypt} from "@/helpers/encrypt";
import {userCan} from "@/utils";
import {UserRole} from "@/utils/auth.roles";
import {mapGetters, mapMutations, mapState} from "vuex";
import {get, post} from "@/services/api";
import {creditOrderAccept, creditOrderSent} from "@/services/endpoint.json";

export default {
  name: "TableCreditHistoryReceived",
  components: {CurrencyPriceView, CreditsView},
  data: () => ({
    fields: [
      {key: 'id', label: 'Order #', sortable: true},
      {key: 'user', label: 'User',sortable: true},
      {key:'description', label: 'Description', sortable: true},
      {key:'status', label: 'Status', sortable: true},
      {key: 'credits', label: 'Credits ordered', class: 'text-right',sortable: true},
      {key: 'totalReceived', label: 'Credits received', class: 'text-right', sortable: true},
      {key: 'price', label: 'Total Price', class: 'text-right', sortable: true},
      {key: 'totalPaid', label: 'Total Paid', class: 'text-right', sortable: true},
      {key: 'totalPending', label: 'Total Pending', class: 'text-right', sortable: true},
      {key: 'discount', class: 'text-right', sortable: true},
      {key: 'createdAt', label: 'Date', sortable: true},
      'actions'
    ],
    arrayData: [],
    pagination: {
      perPage: 20,
      currentPage: 1,
      rows: 0
    },
    users: [],
    filter: {userId: null, status: null},
    currencyDetails: null,
    totalBought: 0,
    arrayOrderStatus: ORDER_CREDIT_STATUS(),
    userSessionId: null
  }),
  mounted() {
    const storage = localStorage.getItem('admin-id')
    this.userSessionId = decrypt(storage)
    if (this.$userCan(UserRole.Admin)) {
      this.filter.userId = 'tvs'
    } else {
      this.filter.userId = null
    }
    this.getData();
    this.loadClients();
  },
  watch: {
    "filter": {
      deep: true,
      handler(e) {
        this.paginate(1)
      }
    },
  },
  computed: {
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser']),
    ...mapState('geo', ['showInLocal'])
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async getData() {
      this.loaderManager(true);
      const filter = `?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&id=${this.filter.userId}&status=${this.filter.status}`
      let {data} = await get(`${creditOrderSent}${filter}`, null, true);
      this.pagination.rows = data.count;
      this.arrayData = data.data
      if (this.$userCan('administrator')) {
        if (this.filter.userId === 'tvs') {
          this.totalBought = data.totalPaid
        } else {
          this.calculateBought(data.data)
        }
      }
      this.loaderManager(false);
    },
    calculateBought(data) {
      this.totalBought = data.filter(x => (x.status === 'Payment confirmed' && x.ownerId === null)).map(x => x.totalPaid).reduce((prev, next) => prev + next, 0)
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
    acceptOrder(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to accept the offer?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then(async res => {
          if (res.isConfirmed) {
            const {data} = await post(creditOrderAccept, {id}, true);
            this.$router.push({
              name: 'credit-order-detail',
              params: {id: id}
            });
          }
        });
    },
    showDetailOrder(id) {
      this.$router.push({
        name: 'credit-order-detail',
        params: {id: id}
      });
    },
    async loadClients() {
      const {data} = await get('user/distributor,dealer,subdealer', null, true);
      if (data.length) {
        if (this.$userCan('administrator')) {
          data.unshift({id: 'tvs', fullName: 'TVS'});
          this.users = data
        } else {
          this.users = data.filter(x => Number(x.id) !== Number(this.userSessionId))
        }
      }
    },
    getCurrencyDetails(item) {
      return (!item.ownerId) ? this.$store.state.geo.tvsCurrency : item.currencyDetails
    },
    getColor(status) {
      if (!!status) {
        return ORDER_CREDIT_STATUS().find(x => x.text === status).color
      } else
        return '#000000'
    },
  },
}
</script>

<style scoped lang="scss">

.align-right {
  display: flex;
  justify-content: flex-end;
}

.btn-right {
  display: flex;
  justify-content: flex-end;
}

.marginBottonZero {
  margin-bottom: 0;
}

.withColumn {
  white-space: nowrap !important;
  overflow: hidden !important;
}
</style>
