<template>
  <div>
    <div class="row">
      <div class="col">
        <b-alert v-model="showError" variant="danger" dismissible>
          Something was wrong. Please check
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h6>Total amount pending: {{ totalAmount|euro }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <b-input placeholder="Amount to pay" v-model.number="amountToPay" type="number"></b-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-button variant="primary" class="btn-block mt-2" @click="selectAllRows">SELECT ALL ORDERS</b-button>
          </div>
          <div class="col">
            <b-button variant="danger" class="btn-block mt-2" @click="processToPay">ADD PAYMENT</b-button>
          </div>
        </div>
      </div>
      <div class="col">
        <b-form-textarea
          id="textarea"
          v-model="description"
          placeholder="Enter description (optional)"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>
    </div>
    <b-table v-if="arrayPendingOrders.length" :items="arrayOrders" :fields="fieldsOrder" class="dataTable" small bordered hover>
      <template #head(credit)>
        Credits Ordered
        <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
      </template>

      <template #cell(credit)="{item}">
        <credits-view :amount="item.credit" :currency-details="item.currencyDetails" :no-show-logo="!showInLocal"
                      symbol-to-end/>
      </template>

      <template #head(totalReceived)>
        Credits Transferred
        <img v-if="!showInLocal" :src="$store.getters['geo/currencyCreditSymbol']" class="symbol">
      </template>
      <template #cell(totalReceived)="{item}">
        <credits-view :amount="item.totalReceived" :currency-details="item.currencyDetails" :no-show-logo="!showInLocal"
                      symbol-to-end/>
      </template>

      <template #cell(price)="{item}">
        <currency-price-view :amount="item.price" :currency-details="getCurrencyDetails(item)" no-switch/>
      </template>

      <template #cell(totalPaid)="{item}">
        <currency-price-view :amount="item.totalPaid" :currency-details="getCurrencyDetails(item)" no-switch/>
      </template>

      <template #cell(pending)="{item}">
        <currency-price-view :amount="(item.price) - item.totalPaid" :currency-details="getCurrencyDetails(item)"
                             no-switch/>
      </template>

      <template #cell(Actions)="{item}" class="p-0">
        <b-checkbox :checked="item.status" variant="primary"
                    @change="selectRow($event, item.id, (item.price - item.totalPaid))"></b-checkbox>
      </template>


    </b-table>
  </div>
</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import {mapGetters, mapState} from "vuex";
import CreditsView from "@/components/common/CreditsView";
import {post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

const fieldsOrdersPending = () => ([
  {key: 'id', label: 'Id'},
  {key: 'invoiceNumber', label: 'Invoice N.'},
  {key: 'credit', label: 'Credits Ordered'},
  {key: 'price', label: 'Total Price'},
  {key: 'totalReceived', label: 'Credits Transferred'},
  {key: 'totalPaid', label: 'Total Paid'},
  {key: 'pending', label: 'Total Pending'},
  'Actions'
])

export default {
  name: "TableOrderPendings",
  components: {CurrencyPriceView, CreditsView},
  mixins: [show_alert_mixin],
  props: {
    arrayPendingOrders: {
      default: []
    }
  },
  computed: {
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser']),
    ...mapState('geo', ['showInLocal']),
    arrayOrders: function () {
      return this.arrayPendingOrders.map(item => ({
        ...item,
        status: true
      }))
    }
  },
  data: () => ({
    fieldsOrder: fieldsOrdersPending(),
    arrayIdsToPay: [],
    amountToPay: 0,
    description: null,
    totalAmount: 0,
    showError: false
  }),
  methods: {
    getCurrencyDetails(item) {
      if (!item.ownerId) {
        return this.$store.state.geo.tvsCurrency;
      } else {
        return item.currencyDetails;
      }
    },
    selectRow(status, id, price) {
      if (status) {
        this.totalAmount += price
        this.arrayIdsToPay.push(id)
      } else {
        this.totalAmount -= price
        const index = this.arrayIdsToPay.findIndex(x => x === id)
        this.arrayIdsToPay.splice(index, 1)
      }
    },
    selectAllRows() {
      this.totalAmount = 0
      this.arrayIdsToPay = [];
      this.arrayOrders.forEach(item => {
        item.status = !item.status
        if (item.status) {
          this.totalAmount += (item.price - item.totalPaid)
          this.arrayIdsToPay.push(item.id)
        }
      })
    },
    async processToPay() {
      if (this.arrayIdsToPay.length && this.amountToPay > 0 && (this.amountToPay <= this.totalAmount.toFixed(2))) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure?',
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then(async res => {
          if (res.isConfirmed) {
            const obj = {
              orders: this.arrayIdsToPay,
              is_payment_complete: false,
              add_all_credits: false,
              amount: this.amountToPay === Number(this.totalAmount.toFixed(2)) ? this.totalAmount : this.amountToPay,
              comment: this.description,
              isPaymentGateway: false
            }
            await post("credit-order/billing-statement-pay", obj, true)
            this.$bvModal.hide("modal-list-orders")
            this.$emit('loadData')
            this.showAlertNotification("Process successful")
          }
        });
      } else {
        this.showError = true
      }
    },
  },
  mounted() {
    if (this.arrayPendingOrders.length) {
      this.arrayIdsToPay.push(...this.arrayPendingOrders.map(el => el.id))
      this.arrayPendingOrders.forEach(el => {
        this.totalAmount += (el.price - el.totalPaid)
      })
    }
  }
}
</script>

<style scoped>

</style>
