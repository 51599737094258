var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"dataTables_wrapper VueTables no-shadow table-responsive"},[_c('b-table',{staticClass:"dataTable",attrs:{"items":_vm.items,"fields":_vm.fields,"small":"","bordered":"","hover":""},scopedSlots:_vm._u([{key:"head(credit)",fn:function(){return [_vm._v(" Credits Ordered "),(!_vm.showInLocal)?_c('img',{staticClass:"symbol",attrs:{"src":_vm.$store.getters['geo/currencyCreditSymbol']}}):_vm._e()]},proxy:true},{key:"cell(credit)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.credit,"currency-details":item.currencyDetails,"no-show-logo":!_vm.showInLocal,"symbol-to-end":""}})]}},{key:"head(credits_gave)",fn:function(){return [_vm._v(" Credits Transferred "),(!_vm.showInLocal)?_c('img',{staticClass:"symbol",attrs:{"src":_vm.$store.getters['geo/currencyCreditSymbol']}}):_vm._e()]},proxy:true},{key:"cell(credits_gave)",fn:function(ref){
var item = ref.item;
return [_c('credits-view',{attrs:{"amount":item.credits_gave,"currency-details":item.currencyDetails,"no-show-logo":!_vm.showInLocal,"symbol-to-end":""}})]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":item.price,"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(paid)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":item.paid,"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(pending)",fn:function(ref){
var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":(item.price) - item.paid,"currency-details":_vm.getCurrencyDetails(item),"no-switch":""}})]}},{key:"cell(Actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.view(item)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])])]}}])})],1),_c('b-modal',{attrs:{"id":"modal-list-orders","title":"Credit order list","size":"lg","hide-footer":""}},[_c('h6',[_vm._v("User: "+_vm._s(_vm.userName)+" ")]),_c('div',{staticClass:"table-responsive"},[(_vm.arrayPendingOrders.length)?_c('table-order-pendings',{attrs:{"array-pending-orders":_vm.arrayPendingOrders},on:{"loadData":_vm.loadData}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }